
    import Vue from 'vue';

    export default Vue.extend({
        name: 'ListTree',
        props: {
            variables: {
                required: true
            },
            name: {
                type: String,
                default: ''
            },
            copied: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            getName(index: string) {
                return this.name ? this.name + '.' + index : index;
            },
            copy(index: string) {
                if (navigator.clipboard) {
                    const label = `{{.${this.getName(index)}}}`;
                    navigator.clipboard.writeText(label).then(() => {
                        this.$notify({
                            group: 'main',
                            title: 'Скопировано',
                            text: `Переменная ${label} скопирована в буфер обмена`
                        });
                    });
                }
            }
        }
    });
