
import Vue from "vue";

import Field from "@/components/Field.vue";
//import Editor from '@/components/editor/editor.vue';
import Editor from "@frontend/custom-ckeditor/editor/editor/editor.vue";
import Modal from "@/components/Modal.vue";
import GenerateFile from "@/components/generatefile/GenerateFile.vue";
import ListTree from "@/components/variables/ListTree.vue";

import RCP from "@/api/RCP.ts";

import createHtml from "@/helpers/createHtml";

import { Template } from "@/types/Template";
import { Version, VersionResponse } from "@/types/Version";
import { ErrorResponse } from "@/types/Errors";
import libraToCKEditor from "@/helpers/libraToCKEditor";

export default Vue.extend({
  name: "documentTemplate",
  data() {
    return {
      form: {
        text: "",
        description: "",
        file_name: "",
        footer: "",
        header: "",
      },
      modals: {
        save: false,
      },
      generateDocumentWindow: false,
      request: false,
      showCode: false,
      routeCheck: false,
      watcherId: () => false as any,
      show: false,
    };
  },
  created() {
    this.versionSet();
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 0);
  },
  watch: {
    currentVersion() {
      this.versionSet();
    },
  },
  computed: {
    template(): Template {
      return this.$store.state.template.info;
    },
    currentVersion(): Version {
      return this.$store.state.template.version;
    },
  },
  methods: {
    saveModal() {
      this.form.description = "";
      this.form.file_name = "";

      this.modals.save = true;
    },
    versionSet() {
      this.routeCheck = false;
      this.watcherId();
      this.watcherId = () => false;

      const text = (this.form.text = libraToCKEditor(this.currentVersion.text));

      if (text.substr(-17) === "</table></figure>") {
        this.form.text += "<p>&nbsp;</p>";
      }

      this.form.file_name = this.currentVersion.file_name;
      this.form.description = this.currentVersion.description;
      this.form.footer = this.currentVersion.footer;
      this.form.header = this.currentVersion.header;

      this.watcherId = this.$watch(
        "form",
        () => {
          this.routeCheck = true;
          this.watcherId();
        },
        {
          deep: true,
        }
      );
    },
    saveVersion(flag = false) {
      return new Promise((resolve, reject) => {
        if (!this.request) {
          this.request = true;

          createHtml(
            this.form.text,
            this.currentVersion.page_property.format,
            this.currentVersion.page_property.orientation
          ).then((text: any) => {
            if (text.substr(-13) === "<p>&nbsp;</p>") {
              text = text.substr(0, text.length - 13);
            }

            const attr = {
              ...this.form,
              footer: this.form.footer
                .replace(
                  '<span class="ck-editor-zaym-to-page">&nbsp;</span>',
                  '<span class="ck-editor-zaym-to-page">{%PAGECOUNT%}</span>'
                )
                .replace(
                  '<span class="ck-editor-zaym-page">&nbsp;</span>',
                  '<span class="ck-editor-zaym-page">{%PAGE%}</span>'
                ),
              header: this.form.header
                .replace(
                  '<span class="ck-editor-zaym-to-page">&nbsp;</span>',
                  '<span class="ck-editor-zaym-to-page">{%PAGECOUNT%}</span>'
                )
                .replace(
                  '<span class="ck-editor-zaym-page">&nbsp;</span>',
                  '<span class="ck-editor-zaym-page">{%PAGE%}</span>'
                ),
              text,
              app_id: this.currentVersion.app_id,
              template_id: this.currentVersion.template_id,
              version: 0,
            };

            if (this.currentVersion.version) {
              attr.version = this.currentVersion.version + 1;
            } else {
              attr.version = 1;
            }

            RCP({
              method: "App.Template.Version.set",
              params: {
                attr,
              },
            })
              .then((result: VersionResponse) => {
                if (result.item) {
                  this.$store.commit("template/setVersion", result.item);
                  this.request = false;
                  this.modals.save = false;

                  if (!flag) {
                    alert("Сохранено : Версия шаблона успешно сохранена");
                  }

                  resolve();
                } else {
                  this.request = false;
                  reject();
                }
              })
              .catch((error: ErrorResponse) => {
                if (error.show) {
                  alert("Ошибка : " + error.message);
                }

                this.request = false;
                reject();
              });
          });
        } else {
          reject();
        }
      });
    },
    publishVersion() {
      return new Promise((resolve, reject) => {
        if (!this.request) {
          this.saveVersion(true)
            .then(() => {
              this.request = true;

              RCP({
                method: "App.Template.Version.publish",
                params: {
                  id: this.currentVersion.id,
                  template_id: this.currentVersion.template_id,
                },
              })
                .then((result: VersionResponse) => {
                  this.request = false;

                  if (result.item) {
                    this.$store.commit("template/setVersion", result.item);
                    alert("Опубликовано : Шаблон успешно опубликован");

                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch((error: ErrorResponse) => {
                  if (error.show) {
                    alert("Ошибка : " + error.message);
                  }

                  this.request = false;
                  reject();
                });
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    deleteTemplate() {
      if (confirm("Вы уверены? : Данное действие невозможно будет отменить")) {
        this.request = true;

        RCP({
          method: "App.Template.del",
          params: {
            pk: this.template.id,
          },
        })
          .then((result: any) => {
            if (result.count) {
              alert("Удалено : Шаблон успешно удален");
              this.$router.push("/templates");
            } else {
              alert(
                "Не удалось удалить шаблон : Произошла ошибка при попытке удалить шаблон"
              );
            }

            this.request = false;
          })
          .catch((error: ErrorResponse) => {
            if (error.show) {
              alert("Ошибка : " + error.message);
            }

            this.request = false;
          });
      }
    },
    checkChanges() {
      return new Promise((resolve) => {
        if (this.routeCheck) {
          if (
            !confirm(
              "Внесены изменения: Сохранить внесенные изменения перед переходом на другую страницу?"
            )
          ) {
            resolve();
          } else {
            return this.saveVersion(true)
              .then(() => {
                resolve();
              })
              .catch(() => {
                alert("Произошла ошибка");
              });
          }
        } else {
          resolve();
        }
      });
    },
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    this.checkChanges().then(next);
  },
  components: {
    Field,
    Editor,
    Modal,
    GenerateFile,
    ListTree,
  },
});
