
    import Vue from 'vue';

    import RCP from '@/api/RCP.ts';

    import { Template } from '@/types/Template';
    import { Version, VersionResponse, VersionsResponse } from '@/types/Version';
    import { ErrorResponse } from '@/types/Errors';

    export default Vue.extend({
        name: 'templateView',
        created() {
            if (this.template.id !== this.id) {
                this.getTemplate();
            } else {
                this.getLastVersion();
            }
        },
        data() {
            return {
                requests: {
                    template: false,
                    lastVersion: false,
                    version: false,
                    createVersion: false
                },
                error: false,
                addInformation: false
            };
        },
        computed: {
            containerClasses(): any {
                const arr = [];

                if (this.currentVersion && this.currentVersion.page_property) {
                    arr.push(`container--editor-${this.currentVersion.page_property.format}`);

                    if (this.currentVersion.page_property.orientation === 'landscape') {
                        arr.push('container--editor-landscape');
                    } else {
                        arr.push('container--editor-book');
                    }
                }

                return arr;
            },
            id(): number {
                return +this.$route.params.id;
            },
            versionId(): number {
                return +this.$route.params.version;
            },
            template(): Template {
                return this.$store.state.template.info;
            },
            currentVersion(): Version {
                return this.$store.state.template.version;
            },
            request(): boolean {
                return this.requests.template
                    || this.requests.lastVersion
                    || this.requests.version
                    || this.requests.createVersion;
            }
        },
        watch: {
            $route(val, prev) {
                if (val.params.id === prev.params.id) {
                    if (val.params.version
                        && val.params.version !== prev.params.version
                        && this.currentVersion.id !== val.params.version) {
                        this.getVersion(+val.params.version).then(() => {
                            if (this.$route.name === 'version') {
                                this.$router.push(`/template/${this.$route.params.id}`);
                            }
                        }).catch((e) => {
                            console.log(e);
                        });
                    }
                } else {
                    this.getTemplate(+val.params.id);
                }
            }
        },
        methods: {
            getTemplate(pk: number = -1) {
                if (pk === -1) {
                    pk = this.id;
                }

                this.requests.template = true;
                this.error = false;
                this.$store.commit('template/clear');

                RCP({
                    method: 'App.Template.get',
                    params: {
                        pk
                    }
                }).then((result: any) => {
                    if (result.item) {
                        this.$store.commit('template/set', result.item);

                        if (this.versionId) {
                            this.getVersion(this.versionId);
                        } else {
                            this.getLastVersion(pk);
                        }
                    }

                    this.requests.template = false;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert("Ошибка : " + error.message);
                    }

                    this.error = true;
                    this.requests.template = false;
                });
            },
            getLastVersion(pk: number = -1) {
                if (pk === -1) {
                    pk = this.id;
                }

                this.requests.lastVersion = true;
                this.error = false;

                RCP({
                    method: 'App.Template.Version.list',
                    params: {
                        filter: {
                            template_id: ['==', pk]
                        },
                        limit: 1,
                        order: [
                            ['version', 'desc']
                        ]
                    }
                }).then((result: VersionsResponse) => {
                    if (result.items[0]) {
                        this.$store.commit('template/setVersion', result.items[0]);
                    } else {
                        this.createVersion();
                    }

                    this.requests.lastVersion = false;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert("Ошибка : " + error.message);
                    }

                    this.error = true;
                    this.requests.lastVersion = false;
                });
            },
            getVersion(pk: number) {
                return new Promise((resolve, reject) => {
                    this.requests.version = true;
                    this.error = false;

                    RCP({
                        method: 'App.Template.Version.get',
                        params: {
                            pk
                        }
                    }).then((result: VersionResponse) => {
                        this.requests.version = false;

                        if (result.item) {
                            this.$store.commit('template/setVersion', result.item);
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch((error: ErrorResponse) => {
                        if (error.show) {
                            alert("Ошибка : " + error.message);
                        }

                        this.requests.version = false;
                        this.error = true;
                        reject();
                    });
                });
            },
            createVersion() {
                this.requests.createVersion = true;
                this.error = false;

                const attr = {
                    app_id: this.template.app_id,
                    template_id: this.template.id,
                    version: 1,
                    generator_file: 'libre',
                    generator_file_in_format: 'html',
                    generator_text: 'golang-template',
                    generator_text_in_format: 'html',
                    page_property: {
                        orientation: 'book',
                        format: 'A4'
                    }
                };

                RCP({
                    method: 'App.Template.Version.set',
                    params: {
                        attr
                    },
                    id: 'createVersion'
                }).then((result: VersionResponse) => {
                    if (result.item) {
                        this.$store.commit('template/setVersion', result.item);
                    } else {
                        this.error = true;
                    }

                    this.requests.createVersion = false;
                }).catch((error: ErrorResponse) => {
                    if (error.show) {
                        alert("Ошибка : " + error.message);
                    }

                    this.error = true;
                    this.requests.createVersion = false;
                });
            }
        }
    });
