export default (html: string) => {
    function replaceElement(source: any, newType: any) {
        const frag = document.createDocumentFragment();
        while (source.firstChild) {
            frag.appendChild(source.firstChild);
        }
        const newElem = document.createElement(newType);
        const attributes = source.attributes;

        [...attributes].forEach((item: any) => {
            newElem.setAttribute(item.name, item.value);
        });

        newElem.appendChild(frag);
        source.parentNode.replaceChild(newElem, source);
    }

    const doc = document.createElement('div');

    doc.innerHTML = html;

    doc.querySelectorAll('img').forEach((item: HTMLImageElement) => {
        const align = item.getAttribute('align');
        const width = item.getAttribute('width');
        const parent = item.parentElement;
        const parentNode = item.parentNode;

        if (parent) {
            parent.classList.add('image');

            if (align && align !== 'center') {
                parent.classList.add(`image-style-align-${align}`);
            }

            if (width) {
                parent.style.width = `${width}px`;
            }

            const text = parent.querySelector('span');
            const p = document.createElement('p');

            if (text) {
                p.innerHTML = text.innerHTML;

                const pNode = parent.parentNode;

                if (pNode) {
                    pNode.insertBefore(p, parent.nextSibling);
                }
                text.remove();
            }

            replaceElement(parent, 'figure');
        }
    });

    return doc.innerHTML;
};
